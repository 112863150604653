<template>
  <div>
    <el-row :gutter="24" style="margin: 0 -6px !important">
      <el-col
        style="padding: 0 8px !important"
        :lg="userInfo.roleId ? 10 : 12"
        :md="12"
      >
        <div class="company-card flex ag-str">
          <div class="flex ag-str" style="width: 100%; height: 100%">
            <div>
              <img
                class="ra-8 w-109 h-109"
                :src="data.companyLogo"
                alt=""
                style="background: #f5f7f9"
              />
            </div>
            <div
              class="flex-clm ju-be ag-str ml-16"
              style="width: 100%; height: 100%"
            >
              <div class="">
                <p class="size-22 color-8">
                  {{ data.companyName }}
                </p>
                <p class="mt-12 size-16 color-9">
                  {{ data.phone }}
                </p>
              </div>
              <div class="flex ju-be" style="width: 100%">
                <p class="size-12 text-right">
                  <span class="color-8">登录时间：</span>
                  <span class="color-2">{{ loginDate || "---" }}</span>
                </p>
                <p
                  class="pointer size-12 txt-udl color-6"
                  @click="$router.push({ path: '/managementAccounts' })"
                >
                  管理账户
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col
        style="padding: 0 8px !important; margin-top: 16px"
        :lg="4"
        v-if="userInfo.roleId"
        :md="12"
      >
        <div class="business-card flex-clm ju-be ag-str">
          <div class="size-16">
            <!-- <i class="el-icon-postcard" style="margin-right: 8px"></i> -->
            <span class="color-9">已开通名片(人)</span>
          </div>
          <p class="size-32">{{ data.currentNum - data.availableNum }}</p>
          <p class="size-12 color-6">
            剩余名片人数<span
              style="margin-left: 5px; font-size: 14px; line-height: 5px"
              >{{ data.availableNum }}</span
            >
          </p>
          <img
            class="bgg-right-img"
            src="@/assets/images/dashboard-card.png"
            alt=""
          />
        </div>
      </el-col>
      <el-col
        style="padding: 0 8px !important"
        :lg="userInfo.roleId ? 10 : 12"
        :md="12"
      >
        <div class="authorization-card flex" style="position: relative">
          <div
            class="authorization-info flex-clm ju-be ag-str"
            style="height: 100%"
          >
            <div class="authorization-information size-16 color-9">
              <!-- <i class="el-icon-info"></i> -->
              <span>授权信息</span>
            </div>
            <div class="authorization-time color-6" style="margin-top:65px;">
              <span
                >{{ data.createTime | getDataTimeSec }}
                <span>（授权开始时间）</span></span
              >
              <!-- <div class="authorization-text color-3">这是您的授权开始时间</div> -->
            </div>
          </div>
          <div id="chartView"></div>
        </div>
      </el-col>
      <!-- <el-col style="padding: 0 8px !important" :lg="6" :md="12">
        <div
          class="announcement-card d-flex flex-column justify-content-center"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="announcement-a">
              <i class="el-icon-message-solid"></i>
              <span>公告</span>
            </div>
            <div class="announcement-more">更多 ></div>
          </div>
          <div class="announcement-list">
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
            <div class="list-item d-flex align-items-center">
              <span>06-02</span>
              <span>18年6月02日重要更新公告22we</span>
            </div>
          </div>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import { fetchCompany } from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import { Chart } from "@antv/g2";
export default {
  data() {
    return {
      data: {},
      day: 0,
      chartData: [{ type: "剩余天数", value: 0 }],
      userInfo: this.$store.state.loginRoot.userInfo,
      loginDate: this.$store.state.loginRoot.loginDate,
    };
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.fetchCompany();
  },
  mounted() {},
  methods: {
    /** */
    async fetchCompany() {
      try {
        const reslut = await fetchCompany();
        this.data = reslut.data;
        console.log("this.data", this.data);
        let createTime = new Date(this.data.createTime);
        localStorage.setItem("createTime", getDataTimeSec(createTime));
        let endTime = new Date(this.data.endTime);
        let days = endTime.getTime() - createTime.getTime();
        this.day = parseInt(days / (1000 * 60 * 60 * 24));

        this.chartData[0].value =
          this.data.maturityDays <= 0 ? 0 : this.data.maturityDays;
        this.chartData[0].type =
          this.data.maturityDays <= 0 ? "已过期" : "剩余天数";

        this.initComponent();
      } catch (error) {
        console.log(error, "err555");
      }
    },
    initComponent() {
      const chart = new Chart({
        container: "chartView",
        autoFit: true,
        height: 300,
        width: 300,
      });
      chart.data(this.chartData);
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
        position: "bottom",
      });
      chart.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color = "#009FE3";
          data.push({ type: "使用天数", value: this.day - data[0].value });
          view.data(data);
          view.coordinate("theta", {
            radius: 0.4,
            innerRadius: 0.8,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#e2e9f1"])
            .style({
              opacity: 1,
            });
          view.annotation().text({
            position: ["50%", "55%"],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: "#E2E9F1",
              fontWeight: 300,
              textBaseline: "bottom",
              textAlign: "center",
            },
            offsetY: -15,
          });

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: "#009FE3",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 10,
          });

          view.interaction("element-active");
        },
      });
      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.co-3d {
  color: #3d66ae;
}
@mixin global-card() {
  height: 157px;
  background: #ffffff;
  border-radius: 2px;
  padding: 24px;
  margin-bottom: 16px;
}
@mixin WidthHeight($width, $height) {
  width: $width;
  height: $height;
}
.company-card,
.business-card,
.authorization-card,
.announcement-card {
  @include global-card();
}
.company-card {
  width: 100%;
  margin-top: 16px;
  border-radius: 12px;
  // background: $color-1;
  .company-logo {
    @include WidthHeight(50px, 50px);
    margin-right: 10px;
  }
  .company-name {
    span {
      font-size: 16px;
      font-family: simhei;
      font-weight: 700;
      line-height: 23px;
      color: $color-1;
      &:nth-of-type(2) {
        font-size: 14px;
        font-weight: lighter;
        margin-top: 6px;
        font-weight: 400;
      }
    }
  }
  .company-time {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: $color-1;
  }
  .company-account {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: $color-1;
    cursor: pointer;
  }
}
.business-card {
  color: $color-1;
  padding: 24px;
  border-radius: 12px;
  position: relative;
  .bgg-right-img {
    position: absolute;
    width: 110px;
    height: 113px;
    right: 0px;
    bottom: 0px;
  }
  // background: url(../../../../../assets/images/dashboard-card.png) 0px 0px no-repeat;
  span {
    font-family: PingFang SC;
    // font-weight: bold;
    font-size: 16px;
  }
  & > span {
    font-size: 48px;
    margin-top: 3px;
  }
}
.authorization-card {
  margin-top: 16px;
  border-radius: 12px;
  .authorization-info {
    .authorization-information {
      // i {
      //   color: $color-1;
      //   margin-right: 8px;
      // }
      span {
        font-size: 16px;
        font-family: PingFang SC;
        color: $color-1;
      }
    }
    .authorization-time {
      height: 28px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-1;
      margin-top: 10px;
    }
    .authorization-text {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-1;
      margin-top: 8px;
    }
  }
  #chartView {
    position: absolute;
    right: 0;
  }
}
.announcement-card {
  .announcement-a {
    i {
      color: $color-1;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #454a4c;
    }
  }
  .announcement-more {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: $color-1;
    cursor: pointer;
  }
  .announcement-list {
    margin-top: 20px;
    .list-item {
      span {
        &:nth-of-type(1) {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.45);
        }
        &:nth-of-type(2) {
          font-size: 14px;
          display: inline-block;
          width: 186px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          margin-left: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &:nth-of-type(2) {
        margin-top: 12px;
      }
    }
  }
}
</style>