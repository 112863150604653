<template>
  <div class="container">
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane label="我的统计" name="first"><my-statistics :type="activeName == 'first' && 1" v-if="activeName == 'first'" /></el-tab-pane>
      <template v-if="userInfo.roleId || jurisdiction || userInfo.main">
        <div class="h-16"></div>
        <el-tab-pane label="企业统计" name="second"><enterprise :type="activeName == 'second' && 2" v-if="activeName == 'second'" /></el-tab-pane>
      </template>
      
    </el-tabs>
  </div>
</template>

<script>
import myStatistics from './components/myStatistics';
import enterprise from './components/enterprise';
import {queryEmpRouter} from '@/api/companyManage';
import { mapState,mapMutations } from 'vuex';
export default {
  name: "dashboard",
  components:{
    myStatistics,
    enterprise
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      activeName:'first',
      jurisdiction:''
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdiction = val.includes('00')
    }
  },
  created(){
    
  },
  methods:{
    // ...mapMutations(['setRouterArr','setRouterArr']),
    // handleClick(tab){
    //   const { name } = tab;
    //   this.activeName = name;
    // },
    // empRouter(){
    //   queryEmpRouter({a:''})
    //   .then(res=>{
    //     let arr = JSON.parse(res.data.routerId)
    //     this.setRouterArr(arr)
    //     this.setEmpId(res.data.empId)
    //     console.log(this.$store.state)
    //   })
    // }
    
  }
};
</script>

<style scoped lang='scss'>
::v-deep.el-tabs__header{
  margin-bottom: 16px !important;
}
</style>