<template>
  <div class="view pa24" style="margin-top: 16px; border-radius: 12px">
    <div class="clearfix">
      <span class="line-left">订单走势</span>
    </div>
    <div id="container"></div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
export default {
  data() {
    return {
      data: [],
    };
  },
  props: {
    sevenDataArr: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    sevenDataArr(val) {
      console.log(val, "valval");
      this.data = val;
      this.initComponent();
    },
  },
  methods: {
    initComponent() {
      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });
      chart.data(this.data);
      chart.scale({
        month: {
          date: [0, 1],
        },
        amount: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("amount", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });

      chart.line().position("date*amount").color("city").shape("smooth");

      chart.point().position("date*amount").color("city").shape("circle");

      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.line-left::before{
  background: #3D66AE !important;
}
</style>