<template>
  <div>
    <el-row :gutter="24">
      <el-col :lg="14">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">待办事项</span>
          </div>
          <div class="d-flex">
            <div class="left-view d-flex flex-column justify-content-between">
              <div>
                <h3 class="color-8">今日需跟进{{ noFollowCount }}人</h3>
                <span class="color-6">已经7天未跟进客户了哦</span>
              </div>
              <el-button
                @click="$router.push({ path: '/customerManagement' })"
                style="background: #3d66ae"
                >去跟进
                <svg class="icon" aria-hidden="true" style="filter:  brightness(0) invert(1)">
                  <use xlink:href="#icon-jiantou_youshang"></use>
                </svg>
              </el-button>
            </div>
            <div class="right-view d-flex flex-column">
              <el-row :gutter="24">
                <el-col :lg="24">
                  <div class="d-flex first-view flex-column">
                    <div class="flex mb-16">
                      <!-- <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-smile"></use>
                      </svg> -->
                      <img
                        class="w-24 h-24"
                        src="@/assets/images/feiji.png"
                        alt=""
                      />
                      <div class="d-flex flex-column text-view ml-16">
                        <span class="color-8">今日已跟进</span>
                        <span class="color-9 mt-6"
                          >客户{{ dealFollowCount }}人</span
                        >
                      </div>
                    </div>
                    <div>
                      <el-progress
                        :percentage="has_follow_today_percent"
                        :format="format"
                        :stroke-width="8"
                        color="#41DDA1"
                      ></el-progress>
                    </div>
                  </div>
                </el-col>
                <el-col :lg="24" style="margin-top: 44px">
                  <div class="d-flex first-view flex-column">
                    <div class="flex mb-16">
                      <!-- <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-frown"></use>
                      </svg> -->
                      <img
                        class="w-24 h-24"
                        src="@/assets/images/weigenjin.png"
                        alt=""
                      />
                      <div class="d-flex flex-column text-view ml-16">
                        <span class="color-8">今日未跟进</span>
                        <span class="color-9 mt-6"
                          >客户{{ no_follow_today }}人</span
                        >
                      </div>
                    </div>
                    <div>
                      <el-progress
                        :percentage="no_follow_today_percent"
                        :format="format"
                        :stroke-width="8"
                        color="#6772E5"
                      ></el-progress>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="10">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">客户兴趣</span>
            <!-- <div class="clearfix-right" style="float: right">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in ['30', '7', '1']"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div style="flex: 1; background: #ffffff; height: 290px">
            <div id="roomTypeCheckIn"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
export default {
  name: "backlog",
  data() {
    return {
      value: "",
      noFollowCount: 0,
      dealFollowCount: 0,
      has_follow_today_percent: 0,
      no_follow_today: 0,
      no_follow_today_percent: 0,
      chartData: [
        { item: "对我感兴趣", value: 0, percent: 0, seeType: 1 },
        { item: "喜欢产品", value: 0, percent: 0, seeType: 2 },
        { item: "看重公司", value: 0, percent: 0, seeType: 3 },
        { item: "关注动态", value: 0, percent: 0, seeType: 4 },
        { item: "预约", value: 0, percent: 0, seeType: 5 },
        { item: "媒体", value: 0, percent: 0, seeType: 6 },
      ],
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
    restChart: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    state(val) {
      const { noFollowCount, dealFollowCount, ownCount } = val;
      this.ownCount = ownCount;
      this.noFollowCount = noFollowCount;
      this.dealFollowCount = dealFollowCount;
      this.has_follow_today_percent = ownCount
        ? (dealFollowCount / ownCount).toFixed(2) * 100
        : 0;
      this.no_follow_today =
        ownCount - dealFollowCount < 0 ? 0 : ownCount - dealFollowCount;
      this.no_follow_today_percent = ownCount
        ? (this.no_follow_today / ownCount).toFixed(2) * 100
        : 0;
    },
    restChart(val) {
      let Arr = JSON.parse(JSON.stringify(val));

      let counet = Arr.reduce((num, item) => {
        return num + item.num;
      }, 0);
      console.log(counet);
      Arr.forEach((item) => {
        this.chartData.forEach((row) => {
          if (item.seeType == row.seeType) {
            console.log((item.num / counet).toFixed(2));
            row.value = item.num;
            row.percent = +(item.num / counet).toFixed(2);
          }
        });
      });

      this.initComponent();
    },
  },

  methods: {
    format(percentage) {
      return " ";
    },
    initComponent() {
      const chart = new Chart({
        container: "roomTypeCheckIn",
        autoFit: true,
        height: 280,
      });
      chart.data(this.chartData);
      console.log(this.chartData);
      chart.coordinate("theta", {
        radius: 0.8,
        innerRadius: 0.8,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: "兴趣",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("item", [
          "#ff5c6c",
          "#7460ee",
          "#24d2b5",
          "#20aee3",
          "#566C84",
          "#FFA133",
        ])
        .tooltip("item*percent", (item, percent) => {
          percent = (percent * 100).toFixed(2) + "%";
          return {
            name: item,
            value: percent,
          };
        });
      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.line-left {
  color: $color-8;
  &::before {
    background: $color-6;
  }
}
::v-deep .el-card {
  border-radius: 12px;
}
.left-view {
  width: 224px;
  height: 284px;
  background: #ffffff;
  border-radius: 0px 0px 0px 2px;
  border-right: 1px solid #e6ebeb;
  padding: 50px 24px;
  h3 {
    font-size: 24px;
    font-family: PingFang SC;
    color: #454a4c;
    margin-bottom: 8px;
  }
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #c0cbcc;
  }
  .el-button {
    width: 126px;
    height: 40px;
    background: $color-1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: $color-1;
    }
  }
}
.right-view {
  padding: 40px 24px;
  flex: 1;
  .first-view {
    .icon {
      height: 50px;
      width: 50px;
      color: #c0cbcc;
    }
    .text-view {
      margin-left: 10px;
      span {
        &:nth-of-type(1) {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #454a4c;
        }
        &:nth-of-type(2) {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c0cbcc;
        }
      }
    }
  }
}
.font-12 {
  font-size: 12px;
}

html body .m-t-30 {
  margin-top: 30px;
}
.text-center {
  text-align: center !important;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}
.bgdanger {
  background: #ff5c6c !important;
}
.bgpurple {
  background: #7460ee;
}
.bgsuccess {
  background: #24d2b5 !important;
}
.bginfo {
  background: #20aee3 !important;
}
</style>