<template>
  <div style="margin-top: 23px">
    <el-row :gutter="24">
      <el-col :lg="10" style="float: right">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">转化率漏斗</span>
            <!-- <div class="clearfix-right" style="float: right">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in ['近30天', '近7天']"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div
            style="height: 320px; position: relative"
            class="d-flex justify-content-center mt20"
          >
            <img
              height="300"
              width="80%"
              src="@/assets/images/loudou.png"
              alt=""
            />
            <p class="look position_a">浏览{{ seeGoodsNum }}</p>
            <p class="palce_order position_a">下单{{ orderCount }}</p>
            <p class="pay position_a">付款{{ payCount }}</p>
            <p class="right1 position_a">
              下单转化率 <br />
              {{ seeGoodsNumRate }}
            </p>
            <p class="right2 position_a">
              付款转化率 <br />
              {{ orderCountRate }}
            </p>
            <p class="right3 position_a">
              成交转化率 <br />
              {{ payCountRate }}
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="14" v-if="type == 1" style="padding: 0">
        <el-col :lg="12">
          <div class="view pa24" style="height: 182px; border-radius: 12px">
            <div slot="header" class="clearfix">
              <span class="line-left">订单总额</span>
            </div>
            <h1 class="bold color-8 size-32">
              ￥{{ state.orderAmount | formatMoney }}
            </h1>
          </div>
        </el-col>
        <el-col :lg="12">
          <div class="view pa24" style="height: 182px; border-radius: 12px">
            <div slot="header" class="clearfix">
              <span class="line-left">订单数量</span>
            </div>
            <h1 class="bold color-8 size-32">{{ state.orderCount || 0 }}单</h1>
          </div>
        </el-col>
      </el-col>
      <el-col
        :lg="14"
        :style="{ marginTop: type == 1 && '35px', padding: '0' }"
      >
        <el-col :lg="type == 1 ? 12 : 24">
          <div
            class="view pa24 d-flex flex-column justify-content-between"
            style="height: 182px; position: relative; border-radius: 12px"
          >
            <div slot="header" class="clearfix flex">
              <span class="line-left">业绩目标</span>
              <div class="ptb-2 plr-10 ra-30 bg-2 size-12 color-5 ml-16">
                月目标 ￥{{ state.targetMoney | formatMoney }}
              </div>
            </div>
            <div>
              <h2>
                <span class="color-8 size-32"
                  >￥{{ state.dealMoney | formatMoney }}</span
                >
                <span class="color-2 size-14 ml-8 fw-4">元（已成交）</span>
              </h2>
            </div>
            <div id="chartDiv"></div>
          </div>
        </el-col>
        <el-col
          :lg="type == 1 ? 12 : 24"
          :style="{ marginTop: type == 2 && '35px' }"
        >
          <div
            class="view pa24 d-flex flex-column justify-content-between"
            style="height: 182px; position: relative; border-radius: 12px"
          >
            <div slot="header" class="clearfix flex">
              <span class="line-left">业绩差额</span>
              <div class="ptb-2 plr-10 ra-30 bg-2 size-12 color-5 ml-16">
                本月已成交{{ state.dealOrderCount }}单
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h2>
                  <span class="size-32 color-8">￥{{ state | dealMoney }}</span>
                  <span class="size-14 color-2 fw-4">（还差）</span>
                </h2>
              </div>
            </div>
            <div id="chartDiv2"></div>
          </div>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";

import { getChangeCount } from "@/api/myStatistics";
export default {
  data() {
    return {
      data: [
        { type: "已完成", count: 3, percent: 1 },
        { type: "未完成", count: 4, percent: 2 },
      ],
      data2: [
        { type: "已完成", count: 0, percent: 0 },
        { type: "业绩差额", count: 0, percent: 0 },
      ],
      value: "",
      seeGoodsNum: 0,
      orderCount: 0,
      payCount: 0,
      seeGoodsNumRate: "",
      orderCountRate: "",
      payCountRate: "",
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  filters: {
    formatMoney(val) {
      return val ? (val / 100).toFixed(2) : 0;
    },
    dealMoney(val) {
      return val.targetMoney <= val.dealMoney
        ? 0
        : (val.targetMoney / 100 - val.dealMoney / 100).toFixed(2);
    },
  },
  watch: {
    state(val) {
      let arr = JSON.parse(JSON.stringify(val));

      this.data[0].percent = arr.targetMoney
        ? +(arr.dealMoney / arr.targetMoney).toFixed(2)
        : 1;
      this.data[1].percent = arr.targetMoney
        ? +((arr.targetMoney - arr.dealMoney) / arr.targetMoney).toFixed(2)
        : 0;
      this.data[0].count = +arr.dealMoney.toFixed(2);
      this.data[1].count = arr.targetMoney
        ? +(arr.targetMoney - arr.dealMoney).toFixed(2)
        : 0;

      this.data2[0].percent = arr.targetMoney
        ? +(arr.dealMoney / arr.targetMoney).toFixed(2)
        : 1;
      this.data2[1].percent = arr.targetMoney
        ? +((arr.targetMoney - arr.dealMoney) / arr.targetMoney).toFixed(2)
        : 0;
      this.data2[0].count = +arr.dealMoney.toFixed(2);
      this.data2[1].count = arr.targetMoney
        ? +(arr.targetMoney - arr.dealMoney).toFixed(2)
        : 0;
      console.log(this.data2[1], "this.data2[1]");
      this.data[1].type = this.data[1].percent > 0 ? "未完成" : "超额完成";
      this.data[1].percent =
        this.data[1].percent > 0 ? this.data[1].percent : -this.data[1].percent;

      this.data2[1].type = this.data2[1].percent > 0 ? "业绩差额" : "超额指数";
      this.data2[1].percent =
        this.data2[1].percent > 0
          ? this.data2[1].percent
          : -this.data2[1].percent;

      this.initComponent();
      this.initComponent2();
    },
  },

  created() {
    this.getChangeCount();
  },
  mounted() {
    setTimeout(() => {}, 1000);
  },
  methods: {
    initComponent() {
      console.log(this.data);
      const chart = new Chart({
        container: "chartDiv",
        autoFit: true,
        height: 300,
        width: 300,
      });

      chart.data(this.data);
      chart.coordinate("theta", {
        radius: 0.4,
        innerRadius: 0.7,
      });
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: "完成度",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: Math.round(this.data2[0].percent * 100) + "%",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          fontWeight: 500,
          textAlign: "center",
        },
        offsetY: 15,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("type", ["#3D66AE", "#ECF4F9"])
        .tooltip("type*percent", (type, percent) => {
          percent = Math.round(percent * 100) + "%";
          return {
            name: type,
            value: percent,
          };
        });
      chart.render();
    },
    initComponent2() {
      const chart = new Chart({
        container: "chartDiv2",
        autoFit: true,
        height: 300,
        width: 300,
      });

      chart.data(this.data2);
      chart.coordinate("theta", {
        radius: 0.4,
        innerRadius: 0.7,
      });
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: this.data2[1].type,
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: Math.round(this.data2[1].percent * 100) + "%",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          fontWeight: 500,
          textAlign: "center",
        },
        offsetY: 15,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("type", ["#3D66AE", "#ECF4F9"])
        .tooltip("type*percent", (type, percent) => {
          percent = Math.round(percent * 100) + "%";
          return {
            name: type,
            value: percent,
          };
        });
      chart.render();
    },
    async getChangeCount() {
      const result = await getChangeCount({
        type: this.type,
        dayNum: this.value,
      });
      const { orderCount, payCount, seeGoodsNum } = result.data;
      this.seeGoodsNum = seeGoodsNum;
      this.orderCount = orderCount;
      this.payCount = payCount;
      this.seeGoodsNumRate = seeGoodsNum
        ? ((orderCount / seeGoodsNum) * 100).toFixed(2) + "%"
        : "0%";
      this.orderCountRate = orderCount
        ? ((payCount / orderCount) * 100).toFixed(2) + "%"
        : "0%";
      this.payCountRate = seeGoodsNum
        ? ((payCount / seeGoodsNum) * 100).toFixed(2) + "%"
        : "0%";
    },
  },
};
</script>

<style lang="scss" scoped>
.line-left {
  color: $color-8;
  &::before {
    background: $color-6;
  }
}
.bold {
  font-size: 48px;
  font-weight: 700;
  color: #84a1c9;
  margin-top: 39px;
}
#chartDiv,
#chartDiv2 {
  position: absolute;
  bottom: -75px;
  right: -50px;
}
.look,
.palce_order,
.pay {
  color: #fff;
  left: 35%;
  font-size: 14px;
}
.position_a {
  position: absolute;
}
.look {
  top: 10%;
}
.palce_order {
  top: 45%;
}
.pay {
  top: 75%;
}
.right1 {
  right: 20%;
  top: 25%;
  color: #67757c;
  font-size: 14px;
}
.right2 {
  top: 55%;
  right: 25%;
  color: #67757c;
  font-size: 14px;
}
.right3 {
  right: 5%;
  top: 50%;
  color: #67757c;
  font-size: 14px;
}
::v-deep .el-card {
  border-radius: 12px;
}
</style>
